import { initializeApp } from 'firebase/app';
// import { isEmulatorDeveloperMode } from "./dashboard-admin.js";

import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyCLuDKqFMOt3dPtqGwdHgFuRtf2Qrp9cQQ",
    authDomain: "tutorifydev.firebaseapp.com",
    projectId: "tutorifydev",
    storageBucket: "tutorifydev.appspot.com",
    messagingSenderId: "125097380511",
    appId: "1:125097380511:web:4b06fbf66e9df24f99b0aa",
    measurementId: "G-0LL18TEBT0"
};

if (firebaseConfig.projectId === "tutorifydev") {

    // bright red big font console.log("DEV MODE")
    console.log("%c ACHTUNG", "color: red; font-size: 18px");
    console.log("%c Config ist TUTORIFYDE DEV FIREBASE", "color: red; font-size: 18px");
}

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp, "europe-west1");


// // DEV

export function connectEmulator() {
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectFirestoreEmulator(db, "localhost", 8080);
    connectAuthEmulator(auth, "http://localhost:9099");
}