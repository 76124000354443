import SplitType from "split-type";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);





function setFontSize() {
    let maxWidth = 1920;
    let windowWidth = $(window).width();
    if (windowWidth >= maxWidth || windowWidth < 992) {
        $('body').removeAttr("style");
    } else {
        let fontSize = (windowWidth / 100) / 16;
        $('body').css('font-size', fontSize + "rem");
    }
}

window.addEventListener("resize", function () {
    setFontSize();
});





export async function initVideo() {

    // get the video element with id "heroVideo"
    var heroVideoWrapper = document.getElementById("heroVideo");
    // get video element in heroVideoWrapper
    var heroVideo = document.querySelector("#heroVideo video");

    // heroVideoActiveWrapper
    var heroVideoActiveWrapper = document.getElementById("heroVideoActiveWrapper");

    // heroVideoButton
    var heroVideoButton = document.getElementById("heroVideoButton");

    /**
     * @dev isVideoPlaying is not used bzw. hat keine Wirkung
     */
    var isVideoPlaying = false;

    // if heroVideo exists
    if (heroVideo) {
        console.log("heroVideo exists")
        // on click, play the video
        heroVideoButton.addEventListener("click", function () {

            // get the current time of the video
            //var currentTime = heroVideo.currentTime;
            // set video src to "https://prod.tutorify.eu/videos/tutorify-erklaerfilm.mp4"
            // create new video element with src "https://prod.tutorify.eu/videos/tutorify-erklaerfilm.mp4"
            var newVideo = document.createElement("video");
            newVideo.src = "https://prod.tutorify.eu/videos/tutorify-erklaerfilm.mp4";
            // set the current time of the video to the current time
            // newVideo.currentTime = currentTime;
            // set autoplay to true
            newVideo.autoplay = true;
            // add controls
            newVideo.controls = true;
            //heroVideo.play();
            heroVideoButton.remove();
            // append it to heroVideoWrapper
            // add class "hero-video-wrapper" to newVideo
            newVideo.classList.add("hero-video-wrapper");
            newVideo.classList.add("active");
            heroVideoActiveWrapper.appendChild(newVideo);
            heroVideoActiveWrapper.classList.add("active");
            isVideoPlaying = true;
            heroVideoWrapper.remove();

            // track event bei Pirsch
            // pirsch("Startseite Video Vollbild", {
            //     duration: currentTime,
            //     meta: {
            //         AbgespieltAb: currentTime
            //     },

            // });



        });

    }
}



// on click "styleguide-cardcheck", search for any element with class of "card is--selected", get the text value of the element

// $("#styleguide-cardcheck").click(function() {
//     // check for checked radio button elements in the div "nachhilfe_dauer_checkboxes"
//     let checked = $("#nachhilfe_dauer_checkboxes input:checked");
//     // get the value of the checked radio button
//     let checkedValue = checked.val();
//     console.log(checkedValue);
// });

// find element with attribute data-w-tab="Verfuegbarkeit" and remove it
//$("[data-w-tab='Verfuegbarkeit']").remove();

// check if any input element with the type of time has changed
$("input[type='time']").change(function () {
    // get the value of the input element that changed
    let value = $(this).val();
    console.log(value);

    // from value, get everything after ":"
    let hours = value.split(":")[0];
    let minutes = value.split(":")[1];
    let newMinutes = parseFloat(minutes);


    // round the value to the nearest 15 minutes
    let roundedValue = Math.round(newMinutes / 15) * 15;
    console.log(roundedValue);

    // if roundedValue is 0, set it to 00
    if (roundedValue == 0) {
        roundedValue = "00";
    }

    // combine hours and ":" and roundedValue
    let newValue = hours + ":" + roundedValue;

    // set the value of the changed element to newValue
    console.log(newValue);
    $(this).val(newValue);
});


// Navbar Styling
function scrollPageNavi() {
    var nav = document.getElementById("navigation");
    nav.classList.add("active");
}

function scrollPageNaviTop() {
    var nav = document.getElementById("navigation");
    nav.classList.remove("active");
}

// Wenn Nutzer ganz oben, dann Navigation transparent, ansonsten weiß

// run only when user is not on the following pages /dashboard

function initNavigation() {
    // if theres an element with id of "navigation" on the page, run the following code
    if (document.getElementById("navigation")) {
        // if the user is not on the dashboard page, run the following code
        window.onscroll = function () {
            const element = document.body
            var scrollPos = element.getBoundingClientRect().top;

            if (scrollPos <= 0 && scrollPos >= -5) {
                scrollPageNaviTop();
            } else if (scrollPos < -5) {
                scrollPageNavi();
            }
        };
    }
}





// Normalize Date 2022-08-18 to 18.08.2022
export function normalizeDate(date) {
    var dateArray = date.split("-");
    var normalizedDate = dateArray[2] + "." + dateArray[1] + "." + dateArray[0];
    return normalizedDate;
}



// Show Popup
function showPopupSubtle(h1text, ptext) {
    const popupWrapper = document.createElement("div");
    popupWrapper.classList.add("popup-wrapper-subtle");

    const popupWrapperStatic = document.createElement("div");
    popupWrapperStatic.classList.add("box-wrapper-static");
    popupWrapper.appendChild(popupWrapperStatic);

    const popupDashboardtext = document.createElement("div");
    popupDashboardtext.classList.add("dashboard-text");
    popupDashboardtext.classList.add("align-center");
    popupWrapperStatic.appendChild(popupDashboardtext);

    const popupH1 = document.createElement("p");
    popupH1.classList.add("text");
    popupH1.classList.add("is--popup-subtle");

    const popupText = document.createElement("p");
    popupText.classList.add("text");

    popupDashboardtext.appendChild(popupH1);
    popupDashboardtext.appendChild(popupText);

    // set text of popupH1 to h1text
    popupH1.innerText = h1text;
    // set text of popupText to ptext
    popupText.innerText = ptext;

    console.log("popupH1: " + popupH1);
    console.log("popupText: " + popupText);

    // add popupWrapper to body
    document.body.appendChild(popupWrapper);
    return popupWrapper;

}

// Show Popup Fullscreen
function createPopupFullscreen(h1text, ptext, acceptUrl, openInNewTab) {
    const popupWrapper = document.createElement("div");
    popupWrapper.classList.add("popup-wrapper");

    const popupWrapperInner = document.createElement("div");
    popupWrapperInner.classList.add("popup-inner");
    popupWrapper.appendChild(popupWrapperInner);

    const popupWrapperStatic = document.createElement("div");
    popupWrapperStatic.classList.add("box-wrapper-static");
    popupWrapperInner.appendChild(popupWrapperStatic);

    const popupDashboardtext = document.createElement("div");
    popupDashboardtext.classList.add("dashboard-text");
    popupDashboardtext.classList.add("align-center");
    popupWrapperStatic.appendChild(popupDashboardtext);


    const popupButtonWrapper = document.createElement("div");
    popupButtonWrapper.classList.add("button-wrapper");

    // create two buttons
    const popupButton1 = document.createElement("button");
    popupButton1.classList.add("button");
    popupButton1.classList.add("popup-false");
    // inner text = "Zurück
    popupButton1.innerText = "Zurück";
    // add eventlistener click and closePopups
    popupButton1.addEventListener("click", closePopups);


    const popupButton2 = document.createElement("a");
    popupButton2.classList.add("button");
    popupButton2.classList.add("primary");
    // inner text = "zur registrierung"
    popupButton2.innerText = "Bestätigen";
    // add acceptUrl as link to popupButton2
    // add event listener, on button press, request to
    // check if acceptUrl is an object, if so, add the event listener to it
    if (typeof acceptUrl === "object") {
        console.log("acceptUrl is an object")
        popupButton2.addEventListener("click", acceptUrl);
        popupButton2.setAttribute("href", "#");
    } else if (acceptUrl === undefined) {
        console.log("acceptUrl is close")
        popupButton2.addEventListener("click", closePopups)
        popupButton2.setAttribute("href", "#");
    } else {
        popupButton2.setAttribute("href", acceptUrl);
        // if openInNewTab is true, add target="_blank" to popupButton2
        if (openInNewTab) {
            popupButton2.setAttribute("target", "_blank");
        }
    }

    // add buttons to buttonWrapper
    popupButtonWrapper.appendChild(popupButton1);
    popupButtonWrapper.appendChild(popupButton2);


    const popupH1 = document.createElement("h1");
    popupH1.classList.add("h1");
    popupH1.classList.add("centered");

    const popupText = document.createElement("p");
    popupText.classList.add("text");
    popupText.classList.add("centered");

    popupDashboardtext.appendChild(popupH1);
    popupDashboardtext.appendChild(popupText);
    popupDashboardtext.appendChild(popupButtonWrapper);

    // set text of popupH1 to h1text
    popupH1.innerText = h1text;
    // set text of popupText to ptext
    popupText.innerText = ptext;

    console.log("popupH1: " + popupH1);
    console.log("popupText: " + popupText);

    // add popupWrapper to body
    document.body.appendChild(popupWrapper);
    return popupWrapper;

}

function autoclosePopup(popupWrapperObject, duration) {
    setTimeout(function () {
        // add class "hidden" to element with class "popup-wrapper-subtle"
        popupWrapperObject.classList.add("hidden");
        setTimeout(function () {
            popupWrapperObject.remove();
        }, 600);
    }, duration);

}
// Close Popups
export function closePopups() {
    // get the element who called the function
    const element = this;
    console.log(element)
    // get the parent element with class "popup-wrapper" of element
    const popupWrapper = element.closest(".popup-wrapper");


    popupWrapper.classList.add("inactive");
    // wait for 600ms and remove popupWrapper
    setTimeout(function () {
        popupWrapper.remove();
    }, 250);

}
/**
 * @param {string} typeOfPopup fullscreen oder subtle
 * @param {string} h1text
 * @param {string} ptext 
 * @param {string} durationInSec 
 * @param {string} linkAccept 
 */
export function makePopup(typeOfPopup, h1text, ptext, durationInSec, linkAccept, openInNewTab) {
    let duration = durationInSec * 1000;

    // if linkAccept is empty, set it to " "
    if (linkAccept == "") {
        linkAccept = " ";
    }


    if (typeOfPopup == "fullscreen") {
        return createPopupFullscreen(h1text, ptext, linkAccept, openInNewTab);
    } else if (typeOfPopup == "subtle") {
        const popupObject = showPopupSubtle(h1text, ptext);
        autoclosePopup(popupObject, duration);
    } else {
        const popupObject = showPopupSubtle(h1text, ptext);
        autoclosePopup(popupObject, duration);
    }



    // on click on element with "popup-wrapper", function closePopups
    // const popupWrapper = document.querySelector(".popup-wrapper");
    // popupWrapper.addEventListener("click", closePopups);

}

// Link timelines to scroll position
function createScrollTrigger(triggerElement, timeline) {
    // Reset tl when scroll out of view past bottom of screen
    ScrollTrigger.create({
        trigger: triggerElement,
        start: "top bottom",
        onLeaveBack: () => {
            timeline.progress(0);
            timeline.pause();
        }
    });
    // Play tl when scrolled into view (60% from top of screen)
    ScrollTrigger.create({
        trigger: triggerElement,
        start: "top 80%",
        onEnter: () => timeline.play()
    });
}



export function splitAndAnimate(inputElementId) {
    // get element by id "inputElementId"
    const inputElement = document.getElementById(inputElementId);
    // Split text into spans
    new SplitType(inputElement, {
        types: "lines",
        tagName: "span"
    });

    // Text Animation
    $(inputElement).each(function () {
        let tl = gsap.timeline({ paused: true, repeat: 0 });
        tl.from($(this).find(".line"), {
            opacity: 0,
            yPercent: 100,
            stagger: 0.1,
            //ease: power1.out,
            ease: "power4.out",

        });
        createScrollTrigger(this, tl);

    })
}




function initiateLoading() { // get all elements with attribute "loading"  

    // check if there are any elements with attribute "loading"
    if (document.querySelectorAll("[loading]").length > 0) {
        var loadingElements = document.querySelectorAll("[loading]");
        // loop through all elements    
        for (var i = 0; i < loadingElements.length; i++) {
            // for every element, paste in a div with class "loading-overlay" and set its width and height to the parents width and height        
            var loadingOverlay = document.createElement("div");
            loadingOverlay.classList.add("loading-overlay");
            var elemWidth = loadingElements[i].offsetWidth;
            var elemHeight = loadingElements[i].offsetHeight;
            if (loadingElements[i].tagName == "P") {
                loadingOverlay.style.width = elemWidth + "px";
                loadingOverlay.style.height = elemHeight + "px";
                // set loading elements width & height to elemWidth & elemHeight            
                loadingElements[i].style.position = "relative";
                loadingElements[i].innerHTML = "";
            } else {
                // set loadingOverlay to absolute            
                loadingOverlay.style.position = "absolute";
                loadingOverlay.style.width = "100%";
                loadingOverlay.style.height = "100%";
                // z index 999            
                loadingOverlay.style.zIndex = "999";
                // set loading element to position relative            
                loadingElements[i].style.position = "relative";
            }
            // paste in loadingOverlay as first child of loading element        
            loadingElements[i].insertBefore(loadingOverlay, loadingElements[i].firstChild);
        }
    } else {
        // if there are not, return
        return;
    }
}




// get element with id "btn-uploadFile" and inside, paste the following svg: https://uploads-ssl.webflow.com/61cc3793b3273f15cfa7b27d/61e538ba1de5a970850a219d_Tutorify_Zuverlaessige_Zahlung_Tutoren_Online_Nachhilfe.svg
export function toggleButtonSuccess(buttonId) {
    // get element with id buttonId, set its current width and height to its current width and height in px.
    var button = document.getElementById(buttonId);
    var buttonWidth = button.offsetWidth;
    var buttonHeight = button.offsetHeight;
    button.style.width = buttonWidth + "px";
    button.style.height = buttonHeight + "px";

    // set the innerHTML to ""
    button.innerHTML = "";

    // if element doesnt have class "active", add it
    if (!button.classList.contains("primary")) {
        button.classList.add("primary");
    }
    // add class "w-button-done" to element with id buttonId
    button.classList.add("success");
}


// go through every img on the page and get the src of the image, set it as its alt attribute and title attribute
function setAltAndTitle() {
    // get all images on the page
    var images = document.querySelectorAll("img");
    // loop through all images
    for (var i = 0; i < images.length; i++) {
        // only set it, if the attribute is empty
        if (images[i].getAttribute("alt") != "") {
            // set the alt attribute to the src attribute
            images[i].setAttribute("alt", images[i].getAttribute("src"));
        }
        if (images[i].getAttribute("title") != "") {
            // set the title attribute to the src attribute
            images[i].setAttribute("title", images[i].getAttribute("src"));
        }
    }
}


// function normalizeStriche() {
//     // get all elements with class "heading-strich" and set the width to its sibling heading element
//     var striche = document.querySelectorAll(".heading-strich");
//     for (var i = 0; i < striche.length; i++) {

//         // get the width of the sibling heading element. dont use offsetWidth, because it includes padding
//         var headingWidth = striche[i].previousElementSibling.offsetWidth;
//         // set the width of the strich to the width of the heading
//         striche[i].style.width = headingWidth + "px";

//     }
// }


// Liste Substatus
export function substatusSchuelerExport() {
    return [
        "Hauptschule",
        "Realschule",
        "Gesamtschule",
        "Gesamtschule Oberstufe",
        "Gymnasium",
        "Gymnasium Oberstufe",
        // "Wirtschaftsgymnasium (5. - 9. Klasse)",
        "Wirtschaftsgymnasium",
        "Wirtschaftsgymnasium Oberstufe"
    ];
}

export function substatusAzubiExport() {
    return [
        "Groß- und Außenhandel",
        "Verkäufer",
        "Einzelhandelskaufleute"
    ];
}
export function substatusStudentExport() {
    return [
        "Jura",
        "Medizin",
        "Wirtschaftswissenschaften",
        "Psychologie"
    ]
}







function checkForheadingWrapper(element) {
    // get parent element of element, and if it's not class "h1_container" add it
    if (!element.parentElement.classList.contains("h1_container")) {
        var h1Container = document.createElement("div");
        h1Container.classList.add("h1_container");
        element.parentNode.insertBefore(h1Container, element);
        h1Container.appendChild(element);
    }
}



// Loading
initiateLoading();

export function initFrontEnd() {

    setFontSize();
    initNavigation();

    //normalizeStriche();
    setAltAndTitle();


    // Init Animations
    // for every h1 element, add the attribute  "text-split"
    const h2s = document.querySelectorAll("h2");
    h2s.forEach(h2 => {
        h2.setAttribute("text-split", "");
        h2.setAttribute("words-slide-up", "");
        checkForheadingWrapper(h2);
    });


    // Split text into spans
    new SplitType("[text-split]", {
        types: "lines",
        tagName: "span"
    });

    // Text Animation
    $("[words-slide-up]").each(function () {
        let tl = gsap.timeline({ paused: true });
        tl.from($(this).find(".line"), {
            yPercent: 100,
            duration: 0.25,
            opacity: 0,
            ease: "power2",
            stagger: { amount: 0.25 }
        });
        createScrollTrigger($(this), tl);
    });

    // Info Cards
    // $("[staggerSlideUp]").each(function(index) {
    //     console.log("staggerSlideUpIndex: " + index)
    //     let tl = gsap.timeline({ paused: true });
    //     tl.from($(this).find(".card"), { opacity: 0, yPercent: 100, duration: 0.5, ease: "back.out(2)", stagger: { amount: 0.5 } });
    //     createScrollTrigger($(this), tl);
    // });

    $("[letters-slide-up]").each(function () {
        let tl = gsap.timeline({ paused: true });
        tl.from($(this).find(".char"), { yPercent: 100, duration: 0.2, ease: "power1.out", stagger: { amount: 0.6 } });
        createScrollTrigger($(this), tl);
    });


    $("[scrub-each-word]").each(function () {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: $(this),
                start: "top 90%",
                end: "top center",
                scrub: true
            }
        });
        tl.from($(this).find(".word"), { opacity: 0.2, duration: 0.2, ease: "power1.out", stagger: { each: 0.4 } });
    });



    // Avoid flash of unstyled content
    gsap.set("[text-split]", { opacity: 1 });
    //gsap.set("[staggerSlideUp]", { opacity: 1 });
}
initFrontEnd();



export function initFaecherCards() {
    // get all elements with class "faecher_card", look for attribute "ttrfyLink". If it exists, add an event listener to it, on click, makePopup
    var faecherCards = document.querySelectorAll(".faecher_card.v2");
    var faecherCardsList = []
    for (var i = 0; i < faecherCards.length; i++) {
        // get the link
        var link = faecherCards[i].getAttribute("ttrfyLink");
        console.log(link)
        // if link exists
        if (link != null) {
            // add faecherCards and link to faecherCardsList
            faecherCardsList.push({ "faecherCard": faecherCards[i], "link": link });
        }
    }

    /** @dev i gibts hier schon... */


    // loop through faecherCardsList and add event listener to each faecherCard
    for (var i = 0; i < faecherCardsList.length; i++) {
        let link = faecherCardsList[i].link;
        faecherCardsList[i].faecherCard.addEventListener("click", function () {
            makePopup("fullscreen", "Jetzt kostenlos registrieren", "Registriere dich jetzt kostenfrei um Nachhilfe für dieses Fach zu erhalten.", 3, link);
        });
    }
}








/* Global Functions */

export function hideLoadingAnimation() {
    // get all elements with class "loader" and set display to none
    const loaderElements = document.getElementsByClassName("loader-wrapper");
    for (var i = 0; i < loaderElements.length; i++) {
        loaderElements[i].style.display = "none";
    }
}
/** Takes parentElement as parameter and hides all loading animations inside of it
 * @param {string} parentElement 
 */
export function hideLoadingAnimation2(parentElement) {
    // find elements with class of "loader-wrapper" inside of parentElement
    const loaderElements = parentElement.getElementsByClassName("loader-wrapper");
    for (var i = 0; i < loaderElements.length; i++) {
        console.log("loaderElements[i]: " + loaderElements[i])
        loaderElements[i].style.display = "none";
    }
}



export function errorMessageToggle(parentElement) {
    const errorClass = "error-message";
    // get all elements with class errorClass and set display to block
    const errorElements = parentElement.getElementsByClassName(errorClass);
    for (var i = 0; i < errorElements.length; i++) {
        errorElements[i].style.display = "block";
    }
}


export function blockButton(button) {
    // if paymentButton doesnt have "blocked class"
    if (button.classList.contains("blocked")) {
        return;
    }

    // paymentButton Styling
    button.classList.add("blocked");
    button.innerHTML = "Lädt...";
    // clone button and replace it
    // var buttonClone = button.cloneNode(true);
    // button.parentNode.replaceChild(buttonClone, button);
    return;
}

/**
 * @param {string} buttonElement
 */

export function checkIfButtonIsBlocked(buttonElement) {
    if (buttonElement.classList.contains("blocked")) {
        console.log("Button is blocked")
        return false;
    }
    return true;
}

/**
 * @param {boolean} boolean
 * @param {string} buttonElement
 */

export function toggleButton(boolean, buttonElement) {
    if (boolean) {
        if (buttonElement.classList.contains("blocked")) {
            // paymentButton Styling
            buttonElement.classList.remove("blocked");
        }
    } else {
        // if paymentButton doesnt have "blocked class"
        if (!buttonElement.classList.contains("blocked")) {
            // paymentButton Styling
            buttonElement.classList.add("blocked");
        }
    }
    return;
}
/**
 * @param {boolean} boolean
 */

export function unBlockButton(button) {
    // if paymentButton doesnt have "blocked class"
    if (button.classList.contains("blocked")) {
        // paymentButton Styling
        button.classList.remove("blocked");
        button.innerHTML = "Erneut bestätigen";
        // clone button and replace it
        return true
    }
    return false;
}


export function checkForMobile() {
    // check if device is mobile
    if (window.innerWidth <= 768) {
        return true;
    }
    return false;
}





export function getUrlParameterByName(inputName) {
    // parse through the url and return the value of the parameter
    // https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript

    // search for inputName in URL and return value
    // if not found, return null
    var url = window.location.href;
    inputName = inputName.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + inputName + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    // decodeURIComponent: decode the value of the parameter
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export function reloadPage() {
    // reload the page
    window.location.reload();
}



export function initFAQ() {
    // if element with class "faq-wrapper-grid" exists
    if (document.querySelector(".faq-wrapper-grid")) {
        console.log("FAQ exists")
        // get all elements with class "c-faq-q" inside it and add eventlistener. on click, toggle "active" on sibling element with class "c-faq-a"
        var faqQuestions = document.querySelectorAll(".faq-wrapper-grid .c-faq-q");
        for (var i = 0; i < faqQuestions.length; i++) {
            faqQuestions[i].addEventListener("click", function () {
                this.nextElementSibling.classList.toggle("active");
            });
        }
    }
}


function checkClamped(elementToCheck) {

    var elementScrollHeight = elementToCheck.scrollHeight;
    var elementClientHeight = elementToCheck.clientHeight;

    console.log("element.scrollHeight: " + elementScrollHeight);
    console.log("element.clientHeight: " + elementClientHeight);

    if (elementScrollHeight > elementClientHeight) {
        // show the "weiterlesen" button
        console.log("element is clamped")
        return true;
    } else {
        return false;
    }
}

// function createWeiterlesenButton() {
//     // create a button element with id "weiterlesen"
//     var weiterlesenButton = document.createElement("a");
//     weiterlesenButton.innerText = "Weiterlesen";
//     return weiterlesenButton;
// }

function unblockClampedLine(elementToUnblock) {
    console.log("Unblocking element: ")
    console.log(elementToUnblock)
    elementToUnblock.style.overflow = "visible";
    elementToUnblock.style.lineClamp = "none";
    elementToUnblock.style.display = "block";


    /**
     * @dev Weiterlesen Button wird nicht angezeigt / gibts net?
     */

    // get parent of element and append the button to it
    if (elementToUnblock.parentNode) {
        // console log attach this to that
        console.log("Attaching the button to the parent of the element")
        console.log(elementToUnblock.parentNode)
        elementToUnblock.parentNode.appendChild(weiterlesenButton);
    }


}

export function initWeiterlesen() {
    // get element with id "test" and check if its clamped
    // if it is clamped, show the "weiterlesen" button

    // get all elements with attribute expandonclick
    var elementsClassesElements = document.querySelectorAll("[expandonclick]");

    // when the element is klicked, expand it and show the "weiterlesen" button
    for (var i = 0; i < elementsClassesElements.length; i++) {
        elementsClassesElements[i].addEventListener("click", function () {
            if (checkClamped(this)) {
                unblockClampedLine(this);
            }
        });
    }


}

export function initOpenChat() {
    // get element with id footer-open-chat and open the user like chat
    var openChatButton = document.getElementById("footer-open-chat");
    openChatButton.addEventListener("click", function () {
        // check for cookie chatStatus
        // if chatStatus is false, show the popup
        if (typeof window.userlike !== 'undefined') {
            window.userlike.userlikeStartChat();
        } else {
            // if chatStatus is true, open the chat
            makePopup("subtle", "Chat starten", "Um den Chat zu starten, musst du die Cookies akzeptieren. Klicke auf den blauen Kreis in der linken Ecke", 3);
        }
    });
}



/**
 * Wandelt die Uhrzeit in eine String um
 * Beispiel: 8.0 zu 8:00 und 8.75 zu 8:45
 * @param {*} uhrzeit 
 * @returns String in schönem Format
 */
export function uhrzeitBeautify(uhrzeit) {
    // a function to convert 8.0 to 8:00 and 8.75 to 8:45
    console.log("UHRZEIT ERHALTEN: " + uhrzeit);
    var uhrzeitString = uhrzeit.toString();
    var uhrzeitArray = uhrzeitString.split(".");
    var uhrzeitHour = uhrzeitArray[0];
    var uhrzeitMinute = uhrzeitArray[1];
    if (uhrzeitMinute == 0) {
        uhrzeitMinute = "00";
    } else if (uhrzeitMinute == 25) {
        uhrzeitMinute = "15";
    } else if (uhrzeitMinute == 5) {
        uhrzeitMinute = "30";
    } else if (uhrzeitMinute == 75) {
        uhrzeitMinute = "45";
    } else {
        uhrzeitMinute = "00";
    }

    var uhrzeitBeautified = uhrzeitHour + ":" + uhrzeitMinute;
    console.log("UHRZEIT BEAUTIFIED: " + uhrzeitBeautified);
    return uhrzeitBeautified;
}

export function killAllTitlesFromCdn() {
    // go through every img element on the page, if the title starts with "https://cdn" delete it
    var images = document.querySelectorAll("img");
    for (var i = 0; i < images.length; i++) {
        if (images[i].title.startsWith("https://cdn")) {
            images[i].title = "";
        }
    }
}


function isInnerTextEmpty(element) {
    // Trim whitespace and check if the innerText is empty
    return element.innerText.trim().length === 0;
}

export function copyToClipboard(elementToCopy) {
    let innerText = elementToCopy.innerText;
    let value = elementToCopy.value;

    console.log("innerText " + innerText)
    console.log(innerText)
    console.log("value " + value)
    console.log(value)

    if (isInnerTextEmpty(elementToCopy)) {
        navigator.clipboard.writeText(elementToCopy.value)
    } else {
        navigator.clipboard.writeText(elementToCopy.innerText)
    }

}

export function initHelpButtons() {
    // get element with class "help-icon-wrapper"
    const helpWrapper = document.getElementsByClassName("help-wrapper");

    // go through all and add event listeners to them
    for (var i = 0; i < helpWrapper.length; i++) {
        const helpText = helpWrapper[i].getElementsByClassName("help-text-wrapper-v2")[0];
        const helpButton = helpWrapper[i].getElementsByClassName("help-icon-wrapper")[0];

        helpButton.addEventListener("mouseover", function () {
            helpText.classList.add("activated");
        });

        helpButton.addEventListener("mouseleave", function () {
            helpText.classList.remove("activated");
        });

        // also add this for clicked on mobile
        helpButton.addEventListener("click", function () {
            helpText.classList.toggle("activated");
        });
    }
}

/**
 * @dev Funktion enthält kritischen Fehler (UC_UI ist nicht definiert)
 */
export function initCookieSettings() {
    // get element with id "cookie-settings"
    const cookieSettings = document.getElementById("cookieSettings");

    // if cookieSettings exists
    if (cookieSettings) {
        // on click on cookieSettings, show the popup
        cookieSettings.addEventListener("click", function () {
            // Check if Usercentrics is initialized
            if (typeof UC_UI !== 'undefined') {
                // Open the Second Layer (Consent Settings)
                UC_UI.showSecondLayer();
            } else {
                console.error('Usercentrics is not initialized');
            }
        });
    }

}


/**
 * Checkt Screen Größe 
 * @param {number} minimumWidth
 * @returns {boolean} 
 */
export function hasSmallScreen(minimumWidth) {
    if (window.innerWidth < minimumWidth) {
        console.log("Screen is smaller than " + minimumWidth)
        return true;
    }
    console.log("Screen is larger than " + minimumWidth)
    return false;
}


function isSmartphoneDevice() {
    // Methode 1: Bildschirmgröße prüfen
    const smallScreenWidth = 768; // Typischer Breakpoint für mobile Geräte
    const isSmallScreen = window.screen.width < smallScreenWidth;

    // Methode 2: Touch-Funktionalität und User-Agent prüfen
    const hasTouchCapability = /Mobile|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);

    // Gerät gilt als Smartphone, wenn es einen kleinen Bildschirm UND Touch-Funktionalität hat
    return isSmallScreen && hasTouchCapability;
}



console.log("Global.js loaded");