import { auth } from "./firebaseSetup.js";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { makePopup } from "./global";
import { authErrorHandling } from "./errorHandling";

console.log("Login.js loaded")

onAuthStateChanged(auth, async(user) => {
    if (user) {
        await routeUser(user);
    } else {
        // No user is signed in.
        console.log("No user is signed in lel");
    }
});

const provider = new GoogleAuthProvider();


async function routeUser(user) {
    await user.getIdTokenResult(true).then(async(idTokenResult) => {
        if (idTokenResult.claims.email_verified !== true) {
            // if user is not verified
            console.log("User is not verified");
            window.location.replace("./login-handler/email-bestatigen");
            // log out user
            //await signOut(auth);
        } else if (idTokenResult.claims.userRole === "user") {
            console.log("User");
            window.location.replace("./dashboard");
        } else if (idTokenResult.claims.userRole === "tutor") {
            console.log("Tutor");
            window.location.replace("./dashboard-tutor");
        }
    });
}

// if user presses enter, run signin function
window.addEventListener("keyup", function(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
        signin();
    }
});

async function signin() {
    signupError.style.display = "none";

    var email = signinEmail.value;
    var password = signinPassword.value;

    await signInWithEmailAndPassword(auth, email, password)
        .then(async(userCredential) => {
            // Müsste routen
            return true;

        })
        .catch((error) => {
            const errorCode = error.code;
            console.log("errorCode" + errorCode);
            const errorMessage = authErrorHandling(errorCode);
            // ..
            makePopup("subtle", "Fehler beim Login", errorMessage, 2)
            signupError.innerText = errorMessage;
            signupError.style.display = "block";
        });
}

// function checkIfUserIsRegisteredCompletly(userId) {
//     try {
//         // in users, look for user with userId
//         const userRef = doc(db, "users", userId);
//         // check if the doc exists, if not, redirect to register, else to dashboard
//         getDoc(userRef).then((docSnapshot) => {
//             if (docSnapshot.exists()) {
//                 console.log("User exists");
//                 // window.location.replace("./dashboard");
//                 // now check if the fields "userRole" are set
//                 const userRole = docSnapshot.data().userRole;
//                 if (userRole === "user") {
//                     console.log("User");
//                     window.location.replace("./dashboard");
//                 } else if (userRole === "tutor") {
//                     console.log("Tutor");
//                     window.location.replace("./dashboard-tutor");
//                 } else {
//                     console.log("User has no userRole");
//                     window.location.replace("./registrierung");
//                 }
//             } else {
//                 console.log("User does not exist");
//                 window.location.replace("./registrierung");
//             }
//         });
//     } catch (error) {
//         console.error(error);
//     }
// }


async function signinGoogle() {
    try {
        // const auth = getAuth();
        const result = await signInWithPopup(auth, provider)
            // This gives you a Google Access Token. You can use it to access the Google API.

        const credential = GoogleAuthProvider.credentialFromResult(result);
        console.log("credential: ");
        console.log(credential);

        const token = credential.accessToken;
        console.log("token: ");
        console.log(token);

        // The signed-in user info.
        const user = result.user;
        console.log("user: ");
        console.log(user)

        const userId = user.uid;
        console.log("userId: ");
        const idTokenResult = await user.getIdTokenResult(true)
        console.log("idTokenResult: ");
        const userRole = idTokenResult.claims.userRole;
        console.log("userRole: " + userRole);


        // if a user is registered completly his userRole is set to "user"
        if (userRole === "user") {
            console.log("User");
            window.location.replace("./dashboard");
        } else {
            window.location.replace("./registrierung");
        }
        // checkIfUserIsRegisteredCompletly(userId);

        //window.location.replace("./dashboard");

    } catch (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        signupError.innerText = errorMessage;
        signupError.style.display = "block";
    }

}



function checkForFilledInputs() {
    console.log("checkForFilledInputs");
    if (signinEmail.value !== "" && signinPassword.value !== "") {
        // add class "primary" to the button
        signinButton.classList.add("primary");
        console.log("Inputs are filled");
    } else {
        // remove class "primary" from the button
        signinButton.classList.remove("primary");
        console.log("Inputs are not filled");
    }
}


async function initMain() {
    // look for inputs element in element with id "loginInputFields"
    const loginInputFields = document.getElementById("loginInputFields");
    // now get look for the inputs in the loginInputFields element
    const signinEmail = document.getElementById("signinEmail");
    const signinPassword = document.getElementById("signinPassword");
    const signinButton = document.getElementById("signinButton");


    checkForFilledInputs();


    // on change, check if both inputs are filled
    loginInputFields.addEventListener("change", () => {
        checkForFilledInputs();
    });



    signinButton.addEventListener("click", async(e) => {
        // e.preventDefault();
        console.log("Signin Button Clicked")
        await signin();
    });
    googlesigninButton.addEventListener("click", async() => {
        try {
            signinGoogle();
        } catch (error) {
            console.error(error);
        }
    });
}




initMain();