// auth/email-already-exists
// auth/invalid-email
// auth/invalid-password
// auth/user-not-found

// Return error message in German
export function authErrorHandling(firebaseErrorCode) {
    var errorMessage;

    switch (firebaseErrorCode) {
        case 'auth/email-already-exists':
            errorMessage = 'Diese E-Mail-Adresse ist bereits registriert.';
            break;

        case 'auth/invalid-email':
            errorMessage = 'Die angegebenen Anmeldedaten sind ungültig.';
            break;

        case 'auth/wrong-password':
            errorMessage = 'Die angegebenen Anmeldedaten sind ungültig.';
            break;

        case 'auth/user-not-found':
            errorMessage = 'Diese E-Mail-Adresse ist nicht registriert.';
            break;

        case 'auth/too-many-requests':
            errorMessage = 'Zu viele Anmeldeversuche. Bitte versuchen Sie es später erneut.';
            break;

        case 'auth/user-disabled':
            errorMessage = 'Dieser Account wurde deaktiviert.';
            break;

        case 'auth/popup-closed-by-user':
            errorMessage = 'Das Popup wurde geschlossen. Bitte versuche es erneut.';
            break;

        default:
            errorMessage = 'Ein Fehler ist aufgetreten.';
            break;
    }

    return errorMessage;
}


export function regErrorHandling(firebaseErrorCode) {
    var errorMessage;

    switch (firebaseErrorCode) {
        case 'auth/email-already-in-use':
            errorMessage = 'Diese E-Mail-Adresse ist bereits registriert.';
            break;

        case 'auth/invalid-email':
            errorMessage = 'Die angegebenen Anmeldedaten sind ungültig.';
            break;

        case 'auth/weak-password':
            errorMessage = 'Das Passwort muss mindestens 6 Zeichen lang sein.';
            break;

        case 'auth/too-many-requests':
            errorMessage = 'Zu viele Anmeldeversuche. Bitte versuchen Sie es später erneut.';
            break;

        case 'userUnter18':
            errorMessage = 'Du musst mindestens 18 Jahre alt sein um dich bei Tutorify registrieren zu können. Frage deinen Erziehungsberechtigten um Hilfe.';
            break;

        case 'felderAusfuellen':
            errorMessage = 'Bitte fülle alle Felder aus.';
            break;

        default:
            errorMessage = 'Ein Fehler ist aufgetreten.';
            break;
    }

    return errorMessage;
}



// Return error message in German
export function pwResetErrorHandling(firebaseErrorCode) {
    var errorMessage;

    switch (firebaseErrorCode) {
        case 'auth/email-already-exists':
            errorMessage = 'Diese E-Mail-Adresse ist bereits registriert.';
            break;

        case 'auth/invalid-email':
            errorMessage = 'Die angegebene E-Mail Adresse ist ungültig.';
            break;

        case 'FirebaseError: The email address is improperly formatted.':
            errorMessage = 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
            break;

        case 'auth/user-not-found':
            errorMessage = 'Diese E-Mail-Adresse ist nicht registriert.';
            break;

        case 'auth/too-many-requests':
            errorMessage = 'Zu viele Anmeldeversuche. Bitte versuchen Sie es später erneut.';
            break;

        case 'auth/user-disabled':
            errorMessage = 'Dieser Account wurde deaktiviert.';
            break;

        default:
            errorMessage = 'Ein Fehler ist aufgetreten.';
            break;
    }

    return errorMessage;
}



// email-verification.js Error Messages
export function resendEmailVerificationErrorHandling(firebaseErrorCode) {
    var errorMessage;

    switch (firebaseErrorCode) {
        case 'TOO_MANY_ATTEMPTS_TRY_LATER':
            errorMessage = 'Bitte versuche es später erneut.';
            break;
        default:
            errorMessage = 'Ein Fehler ist aufgetreten.';
            break;
    }

    return errorMessage;
}